/* News Ticker */
@-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    
    position: fixed;
    bottom: 0;
    left: calc(50% - 50vw);
    /* margin-right: calc(50% - 50vw); */
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: var(--green); 
    padding-left: 100%;
    box-sizing: content-box;
  }
  
  .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;  
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
  
    -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-name: ticker;
            animation-name: ticker;
    -webkit-animation-duration: var(--ticker-duration);
            animation-duration: var(--ticker-duration);
  }
  
  .ticker:hover {
    animation-play-state: paused;
  }
  
  .ticker__item {
  
    display: inline-block;
  
    padding: 0 2rem;
    font-size: 1.5em;
    color: var(--font-secondary);   
  
  }
  