:root {
  --dark-blue: #212D40;
  --light-blue: #004E98;
  --pink: #D90368;
  --red: #EA5155;
  --yellow: #FA9F42;
  --green: #0B6E4F;
  --grey: #525252;
  --font-primary: #525252;
  --font-secondary: #ffffff;
  --ticker-duration: 60s;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-primary);
  margin-bottom: 116px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Brand Colours */
.bg-brand-dark-blue {
  background-color: var(--dark-blue) !important;
}

.bg-brand-light-blue {
  background-color: var(--light-blue) !important;
}

.bg-brand-dark-pink {
  background-color: var(--pink) !important;
}

.bg-brand-light-pink {
  background-color: var(--red) !important;
}

.bg-brand-steel {
  background-color: #c8c7e5 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-brand-blue-gradient {
  background: linear-gradient(135deg, var(--dark-blue) 0%, var(--dark-blue) 25%, var(--light-blue) 100%) !important;
}

.bg-brand-pink-gradient {
  background: linear-gradient(135deg, var(--pink) 0%, var(--pink) 25%, var(--red) 100%) !important;
}

.border-brand-light-blue {
  border-color: var(--light-blue) !important;
}

.border-brand-dark-pink {
  border-color: var(--pink) !important;
}

.font-brand-dark-blue {
  color: var(--dark-blue) !important;
}

.font-brand-light-blue {
  color: var(--light-blue) !important;
}

.font-brand-dark-pink {
  color: var(--pink) !important;
}

.font-brand-light-pink {
  color: var(--red) !important;
}

.font-brand-steel {
  color: #c8c7e5 !important;
}

.font-primary {
  color: var(--font-primary) !important;
}

.font-secondary {
  color: var(--font-secondary) !important;
}

.font-2 {
  font-size: 2em;
}

.font-4 {
  font-size: 4em;
}
