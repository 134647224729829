/* Hero Banner */


.hero-img {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    height:calc(13vh);
    overflow: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                url("../../images/hero-img.jpg");
    background-size: cover;
    background-position: 50% 70%;
    box-shadow: 0 4px 10px 0 rgba(0,0,0, 0.6);
    position: relative;
  }
  
  .hero-text {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    height:calc(13vh);
    width:90%;
    text-align: center;
    position: absolute;
    /* top: 50%;
    left: 50%; */
    /* transform: translate(-50%, -50%); */
    color: var(--font-primary);
  }
  
  .hero-header {
    margin:0;
    width:80%;
    font-size: 4em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-block-end: 0;
    margin-block-start:0;
  }
  
  .hero-header-mob {
    width:100%;
    font-size: 3em;
    margin:0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-block-end: 0;
    margin-block-start:0;
  }

  .home-img-container {
    position: relative;
  }

  .home-img-fh {
    margin-left: -200px;
    background: linear-gradient(to right, #FFF, rgba(0, 0, 0, 0)) 20%,
                url("../../images/home-img.jpg");
    background-size: cover;
    background-position: 50% 70%;
    height: calc(80vh);
    position: relative;
    z-index: -50;
  }