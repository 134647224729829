/* PAGE LOADER */

.loader-body {
    background-color: rgba(87, 87, 86, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1001;
    top: 0px;
    left: 0px;
  }
  
.loader {
    position: absolute;
    top: calc(50% - 200px);
    left: calc(50% - 60px);
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    border: 16px solid #bcbcbb;
    width: 120px;
    height: 120px;
    border-top-color: var(--light-blue);
    border-radius: 50%;
    animation: coloredspin 8s linear infinite;
    z-index: 5000;
}
  
@keyframes coloredspin {
    0% {
        transform: rotate(0deg) scale(1);
        border-top-color:  var(--light-blue);
    }
    25% {
        border-top-color:  var(--pink);
    }
    50% {
        transform: rotate(720deg) scale(1.05);
        border-top-color: var(--red);
    }
    75% {
        border-top-color: var(--yellow);
    }
    100% {
        transform: rotate(1440deg) scale(1);
        border-top-color: var(--light-blue);
    }
}