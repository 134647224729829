.todo-panel {
  /* width: calc(25vw); */
  background-color: var(--yellow);
  padding: 10px;
  height: calc(100vh - 220px);
}

#otherToDo {
  height: 50%;
  overflow-y: scroll;
}

.strikethrough {
    text-decoration: line-through
  }