/* Sign-In/Sign-UP */

.auth-form {
    max-width: 600px;
    margin:auto;
    padding: 15px;
    border: 2px solid #046ccc;
    border-radius: 15px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.6);
  }
  
  .auth-form .input-group-prepend, 
  .w-50px {
    width: 50px;
  }
  
  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text {
    width: 100%;
  }
  
  .input-group-text svg {
    margin:auto;
    color:#fff;
  }
  
  .fa-li {
    top: 0.2em;
  }