#linkSection {
    height: calc(100vh - 220px);
    overflow-y: hidden;
  }
  
.linkTab {
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
}

a.nav-link:not(.active) {
  color: var(--font-secondary);
}

.link-card{
  overflow: hidden;
}

.link-card .link-delete {
  /* display: inline-block !important; */
  text-align: center;
  margin: 0;
  width: 0;
  padding:0;
  border: none;
  border-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition:all  0.5s ease-in-out;
}

.link-delete div {
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
}

.link-card:hover .link-delete {
  /* display: block !important; */
  width: 40px;
  -webkit-transition-delay: 2s;
  -moz-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}